import MaxWidth from 'components/functional/maxWidth'
import Layout from 'components/global/layout'
import PageBody from 'components/shared/pageBody'
import PageHeader from 'components/shared/pageHeader'
import { graphql } from 'gatsby'

import React from 'react'

const Contact = ({ data }) => {
  const mob = data.mobHeaderImg
  const tab = data.tabletHeaderImg
  const desk = data.deskHeaderImg
  const meta = {
    title: 'Contact us | The Mousetrap',
  }
  return (
    <Layout title={meta.title}>
      <PageHeader mobileHeader={mob} tabletHeader={tab} desktopHeader={desk} />
      <MaxWidth $wooden>
        <PageBody>
          <h1>Contact US</h1>
          <div>
            <strong>In Person</strong>
            <p>
              From 10am to 8pm Monday to Sunday, St. Martin’s Theatre, West St,
              London WC2H 9NZ
            </p>
          </div>
          <div className="contact">
            <strong>Phone (Box Office)</strong>
            <p>
              <a href="tel:+4420 7836 1443">+4420 7836 1443</a>
            </p>
          </div>
          <div className="contact">
            <strong>Stage Door</strong>
            <p>
              Phone: <a href="tel:+4420 7836 1086">+4420 7836 1086</a>
            </p>
          </div>
          <p>
            If your enquiry relates to a ticket purchase, please contact the Box
            Office directly on <a href="tel:+4420 7836 1443">+4420 7836 1443</a>.
          </p>
          <p>
            For general enquiries please direct correspondence to{' '}
            <a href="mailto:enquiries@stmartinstheatre.co.uk">
              enquiries@stmartinstheatre.co.uk
            </a>
            . All emails are read and responded to as soon as possible. However,
            if your enquiry is urgent, please contact the Box Office directly on{' '}
            <a href="tel:+4420 7836 1443"> +4420 7836 1443</a>.
          </p>
          <p>
            Job applications should be submitted in writing to the Theatre
            Manager.
          </p>
          <strong className="email">
            For Press Enquiries, please contact{' '}
            <a
              href="mailto:david.bloom@storyhousepr.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              david.bloom@storyhousepr.co.uk
            </a>{' '}
            and{' '}
            <a
              href="mailto:lewis.jenkins@storyhousepr.co.uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              lewis.jenkins@storyhousepr.co.uk
            </a>
          </strong>
        </PageBody>
      </MaxWidth>
    </Layout>
  )
}

export default Contact

export const ContactQuery = graphql`
  query {
   mobHeaderImg: file(name: { eq: "About-the-theatre_575x570" }) {
      childImageSharp {
        gatsbyImageData(
          width: 575
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    tabletHeaderImg: file(name: { eq: "About-the-theatre_991x570" }) {
      childImageSharp {
        gatsbyImageData(
          width: 991
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    deskHeaderImg: file(name: { eq: "about-the-theatre-copy-min" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
