import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const PageBodyStyles = styled.section`
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 3rem 0 3rem 0;
  text-align: center;

  h1 {
    text-transform: uppercase;
    font-size: 2.5rem;
  }
  .pageTitle {
    margin-top: 2rem;
  }
  h3 {
    text-transform: uppercase;
    font-size: 1.85rem;
  }
  h4 {
    margin-top: 0.75rem;
  }
  .access-next-date {
    margin-top: 0.75rem;
    display: block;
    font-size: 1.15rem;
  }
  .sub-header {
    text-transform: uppercase;
    margin-top: 2rem;
    @media ${media.md} {
      font-size: 2.5rem;
    }
  }
aside {
  width: 300px;
  height: auto;
  text-align: center;
  margin: 1.5rem auto 0 auto;
  @media ${media.md} {
    width: 400px;
  }
  @media ${media.xl} {
    width: 500px;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }
}
  svg {
    width: 90%;
    margin: 2rem auto 1rem auto !important;
    max-width: 300px;
    display: inline-block !important;
  }
  .contact {
    margin: 1rem 0;
    p {
      @media ${media.md} {
        font-size: 22px !important;
      }
      a {
        color: var(--white) !important;
        font-weight: 600;
      }
    }
  }
  a {
    color: var(--white) !important;
    text-decoration: underline!important;
  }
  .email {
    font-size: 1.3rem;
    a {
      color: var(--white);
    }
  }
  > div {
    margin: 1.5rem 0;
    strong {
      font-size: 1.25rem;
    }
  }
  ol {
    list-style-position: inside;
  }
  .smaller-text {
    font-size: 19px;
  }
  > p {
    margin: 1rem 0;
    a {
      text-decoration: underline;
    }
  }

  @media ${media.md} {
    width: 75%;
    margin: var(--auto);
    > div {
      margin: 3rem;
      strong {
        font-size: 1.5rem;
      }
    }
    > p {
      font-size: 1.15rem;
      margin-bottom: 1.5rem;
    }
  }
  @media ${media.xl} {
    > p {
      font-size: 1.2rem;
    }
  }
`
const PageBody = props => (
  <PageBodyStyles {...props}>{props.children}</PageBodyStyles>
)

export default PageBody
